<template>
  <b-container class="mt-4">
    <b-modal id="deleteAccreditation" title="Dikkat!" @ok="deleteAccreditation">
      <p class="my-4">Akreditasyon kaydını silmek istediğinize emin misiniz?</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>
    <b-modal id="history" title="Akreditasyon Geçmişi" ok-only>
      <div
        v-if="
          accreditationType && accreditations[accreditationType].length == 0
        "
      >
        <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
      </div>
      <b-table
        :fields="fields"
        :items="accreditations[accreditationType]"
        striped
        responsive
        v-else
      >
        <template #cell(id)="id">
          <b-badge variant="secondary">
            {{ id.index + 1 }}
          </b-badge>
        </template>
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'name' ? '80%' : '10%' }"
          />
        </template>
        <template #cell(name)="row">
          {{ fieldToName(row.item.role) }}
        </template>
        <template #cell(tools)="row">
          <b-button
            size="sm"
            v-b-modal.deleteAccreditation
            @click="accreditationId = row.item.id"
            class="btn-danger"
          >
            <b-icon-trash></b-icon-trash>
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <b-form>
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-4 text-white"></label>
          <b-button
            variant="dark"
            class="mr-4"
            to="/clubs"
            v-if="hasRole(['federation'])"
          >
            Kulüpler
          </b-button>
        </template>
        <b-row align-h="around">
          <b-col cols="auto">
            <b-img :src="getClubImage" height="192" width="160"></b-img>
          </b-col>
          <b-col cols="auto">
            <b-img :src="getClubTeamImage" height="192" width="384"></b-img>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="8">
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Kod :</div>
              </b-col>
              <b-col
                ><div class="mt-4 font-weight-bold">{{ form.id }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Adı :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">{{ form.name }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Başkan :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.minister }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Genel Sekreter :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.secretary }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Kulüp Antrenörü :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.clubTrainer1 ? form.clubTrainer1 : "Yok" }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Kulüp Antrenörü :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.clubTrainer2 ? form.clubTrainer2 : "Yok" }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Kulüp Antrenörü :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.clubTrainer3 ? form.clubTrainer3 : "Yok" }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Bölge :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.region | convertCity }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Tescil No :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.registrationNumber }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Kuruluş Tarih :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.foundationDate
                      ? form.foundationDate.split("-").reverse().join(".")
                      : ""
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Kapanış Tarih :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.closingDate
                      ? form.closingDate.split("-").reverse().join(".")
                      : ""
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Adres :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.address }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Telefon :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.phone }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Akarsu Son Akredite Yılı :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.streamWaterLastAccreditedYear
                      ? form.streamWaterLastAccreditedYear
                      : "Yok"
                  }}
                  <span v-if="hasRole(['federation'])">
                    <b-button
                      @click="accreditateClub('streamWaterLastAccreditedYear')"
                      variant="success"
                      class="ml-4"
                    >
                      Akredite Et
                    </b-button>
                    <b-button
                      v-b-modal.history
                      @click="
                        accreditationType = 'streamWaterLastAccreditedYear'
                      "
                      variant="primary"
                      class="ml-4"
                    >
                      Geçmiş
                    </b-button>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Durgunsu Son Akredite Yılı :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.stillWaterLastAccreditedYear
                      ? form.stillWaterLastAccreditedYear
                      : "Yok"
                  }}
                  <span v-if="hasRole(['federation'])">
                    <b-button
                      @click="accreditateClub('stillWaterLastAccreditedYear')"
                      variant="success"
                      class="ml-4"
                    >
                      Akredite Et
                    </b-button>
                    <b-button
                      v-b-modal.history
                      @click="
                        accreditationType = 'stillWaterLastAccreditedYear'
                      "
                      variant="primary"
                      class="ml-4"
                    >
                      Geçmiş
                    </b-button>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Deniz Kanosu Son Akredite Yılı :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.seaKayakingLastAccreditedYear
                      ? form.seaKayakingLastAccreditedYear
                      : "Yok"
                  }}
                  <span v-if="hasRole(['federation'])">
                    <b-button
                      @click="accreditateClub('seaKayakingLastAccreditedYear')"
                      variant="success"
                      class="ml-4"
                    >
                      Akredite Et
                    </b-button>
                    <b-button
                      v-b-modal.history
                      @click="
                        accreditationType = 'seaKayakingLastAccreditedYear'
                      "
                      variant="primary"
                      class="ml-4"
                    >
                      Geçmiş
                    </b-button>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Dragon Bot Son Akredite Yılı :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.dragonBoatLastAccreditYear
                      ? form.dragonBoatLastAccreditYear
                      : "Yok"
                  }}
                  <span v-if="hasRole(['federation'])">
                    <b-button
                      @click="accreditateClub('dragonBoatLastAccreditYear')"
                      variant="success"
                      class="ml-4"
                    >
                      Akredite Et
                    </b-button>
                    <b-button
                      v-b-modal.history
                      @click="accreditationType = 'dragonBoatLastAccreditYear'"
                      variant="primary"
                      class="ml-4"
                    >
                      Geçmiş
                    </b-button>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Rafting Son Akredite Yılı :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.raftingLastAccreditYear
                      ? form.raftingLastAccreditYear
                      : "Yok"
                  }}
                  <span v-if="hasRole(['federation'])">
                    <b-button
                      @click="accreditateClub('raftingLastAccreditYear')"
                      variant="success"
                      class="ml-4"
                    >
                      Akredite Et
                    </b-button>
                    <b-button
                      v-b-modal.history
                      @click="accreditationType = 'raftingLastAccreditYear'"
                      variant="primary"
                      class="ml-4"
                    >
                      Geçmiş
                    </b-button>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Faks :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">{{ form.fax }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  E-Posta Adres :
                </div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.email }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Web Adres :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.website }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">Açıklama :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{ form.description }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <h4><b-badge class="mt-4"> ANTRENÖRLER </b-badge></h4>
        <ClubTable
          :tableItems="getClubTrainers"
          key="trainer"
          :tableFields="clubMemberFields"
          @openDetail="openTrainerDetail($event)"
          @editClick="editTrainer($event)"
          @multipleDeleteAction="deleteMultiple($event)"
        />
        <h4><b-badge class="mt-4"> SPORCULAR </b-badge></h4>
        <ClubTable
          :tableItems="getClubMembers"
          key="sports"
          :tableFields="clubMemberFields"
          @openDetail="openMemberDetail($event)"
          @editClick="editMember($event)"
          @multipleDeleteAction="deleteMultiple($event)"
        />
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import ClubTable from "@/components/tables/ClubMemberTable.vue";
import { mapGetters } from "vuex";
import cities from "@/store/constants/cities";
export default {
  components: { ClubTable },
  name: "ClubDisplay",
  props: ["title", "data"],
  filters: {
    convertCity(value) {
      return value && cities
        ? cities.filter((e) => e.value == value)[0].text
        : "";
    },
  },
  created() {
    if (this.$store.getters.checkToken.isFederation) {
      this.$store.dispatch("initSingleClub", this.$route.params.id);
      this.$store.dispatch("initClubMembers", this.$route.params.id);
      this.$store.dispatch("initClubAccreditations", this.$route.params.id);
      this.$store.dispatch("initClubImages", this.$route.params.id);
    } else {
      this.$store.dispatch("initSingleClub", this.$store.getters.getUser.id);
      this.$store.dispatch("initClubMembers", this.$store.getters.getUser.id);
    }
  },

  computed: {
    ...mapGetters({
      getClubMembers: "getClubMembers",
      getClubTrainers: "getClubTrainers",
      getClubAccreditations: "getClubAccreditations",
      getClub: "getSingleClub",
      getClubImage: "getClubImage",
      getClubTeamImage: "getClubTeamImage",
    }),
    form: {
      get() {
        return this.getClub;
      },
    },
    accreditations: {
      get() {
        const accs = {
          streamWaterLastAccreditedYear: [],
          stillWaterLastAccreditedYear: [],
          seaKayakingLastAccreditedYear: [],
          dragonBoatLastAccreditYear: [],
          raftingLastAccreditYear: [],
        };
        this.getClubAccreditations.forEach((e) => {
          accs[e.role].push(e);
        });
        return accs;
      },
    },
  },
  data() {
    return {
      cities: cities,
      accreditationId: null,
      accreditationType: null,
      clubMemberFields: [
        {
          key: "id",
          label: "Sıra No",
        },
        {
          key: "ssn",
          label: "TC Kimlik No",
        },
        {
          key: "name",
          label: "Ad Soyad",
        },
        this.$store.getters.checkToken.isFederation
          ? {
              key: "events",
              label: "İşlemler",
            }
          : null,
      ],
      fields: [
        { label: "Sıra", key: "id" },
        { label: "Branş", key: "name" },
        { label: "Akreditasyon Yılı", key: "date" },
        { label: "İşlemler", key: "tools" },
      ],
    };
  },
  methods: {
    accreditateClub(role) {
      const date = new Date().getFullYear();
      this.$store
        .dispatch("accreditateClub", {
          clubId: this.form.id,
          date,
          role,
        })
        .then(() => {
          this.$store.dispatch("notification/setNotifications", [
            {
              title: "Başarılı!",
              body: "Akreditasyon başarıyla tamamlanmıştır!",
              type: "success",
            },
          ]);
        });
    },
    fieldToName(field) {
      if (field.startsWith("stream")) {
        return "Akarsu";
      } else if (field.startsWith("still")) {
        return "Durgunsu";
      } else if (field.startsWith("sea")) {
        return "Deniz Kanosu";
      } else if (field.startsWith("dragon")) {
        return "Dragon Bot";
      }else if (field.startsWith("rafting")){
        return "Rafting"
      }
      
    },
    deleteAccreditation() {
      this.$store.dispatch("deleteAccreditation", {
        id: this.accreditationId,
        clubId: this.$route.params.id,
      });
    },
    openTrainerDetail(index) {
      this.$router.push({
        name: "PersonelDetail",
        params: this.getClubTrainers[index],
      });
    },

    openMemberDetail(index) {
      this.$router.push({
        name: "PersonelDetail",
        params: this.getClubMembers[index].id,
      });
    },
    editTrainer(index) {
      this.$router.push({
        name: "EditPersonel",
        params: this.getClubTrainers[index],
      });
    },
    editMember(index) {
      this.$router.push({
        name: "EditPersonel",
        params: this.getClubMembers[index],
      });
    },
    deleteMultiple(indexes) {
      this.$store.dispatch("deletePersonelFromClub", {
        id: indexes[0],
        clubId: this.$route.params.id,
      });
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>