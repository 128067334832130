<template>
  <ClubDisplay v-bind="props" />
</template>

<script>
import ClubDisplay from "@/components/forms/ClubDisplay.vue";
export default {
  components: { ClubDisplay },
  data() {
    return {
      props: {
        title: "Kulüp Bilgileri",
        data: this.$route.params,
      },
    };
  },
};
</script>

<style scoped>
</style>