<template>
  <div>
    <b-modal
      id="singleDeletion"
      ref="singleDeletion"
      title="Dikkat!"
      @ok="deleteSingleItem"
    >
      <p class="my-4">
        Personeli kulüpten çıkarmak istediğinizden emin misiniz?
      </p>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>

    <div v-if="tableItems.length == 0">
      <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
    </div>
    <div v-else>
      <b-table :items="tableItems" :fields="tableFields">
        <template #cell(name)="row">
          <div style="width: 24rem">
            <router-link
              v-if="hasRole(['federation'])"
              v-text="
                tableItems[row.index].name + ' ' + tableItems[row.index].surname
              "
              :to="{name: 'PersonelDetail', params:{id: row.item.id}}"
            ></router-link>
            <span v-else>{{
              tableItems[row.index].name + " " + tableItems[row.index].surname
            }}</span>
          </div>
        </template>

        <template #cell(events)="row" v-if="hasRole(['federation'])">
          <b-button-group>
            <b-button size="sm" @click="editItem(row.index)">
              <b-icon-pencil></b-icon-pencil>
            </b-button>
            <b-button
              size="sm"
              @click="showDeleteModal(row.index)"
              class="btn-danger"
            >
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-button-group>
        </template>
        <template #cell(id)="id">
          <b-badge variant="secondary">{{ id.index + 1 }}</b-badge>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClubTable",
  props: ["tableFields", "tableItems"],

  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  data() {
    return {
      singleDeletingItem: null,
    };
  },

  methods: {
    deleteSingleItem() {
      this.$emit("multipleDeleteAction", [
        this.tableItems[this.singleDeletingItem].id,
      ]);
    },
    editItem(id) {
      this.$emit("editClick", id);
    },
    openDetail(id) {
      this.$emit("openDetail", id);
    },
    showDeleteModal(index) {
      this.singleDeletingItem = index;
      this.$refs.singleDeletion.show();
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
  },
};
</script>

<style scoped>
.align-right {
  margin-right: 1rem;
}
</style>